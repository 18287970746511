<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="关键字" class="keywords">
          <el-input placeholder="请输入" v-model="searchData.keywordValue" class="input-with-select" size="small">
            <el-select v-model="searchData.keywordName" slot="prepend" placeholder="请选择" size="small">
              <el-option v-for="item in searchTypeOrder" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="还款周期">
          <el-select style="width:200px;" v-model="model" placeholder="请选择" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="下单时间">
          <el-date-picker
            style="width:310px;"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select v-model="searchData.orderType" placeholder="请选择" size="small" clearable>
            <el-option v-for="item in dict_order_type" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户逾期天数">
          <el-input-number
            placeholder="请输入"
            size="small"
            style="width:138px"
            v-model="searchData.leftMemberOverdueDays"
            :step="1"
            :min="null"
            step-strictly
            controls-position="right"
          ></el-input-number>
          <span> 至 </span>
          <el-input-number
            placeholder="请输入"
            size="small"
            style="width:138px"
            v-model="searchData.rightMemberOverdueDays"
            :step="1"
            :min="null"
            step-strictly
            controls-position="right"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="是否监管" class="order-type">
          <el-select v-model="searchData.enableAbm" placeholder="请选择" size="small" clearable>
            <el-option label="是" :value="true"> </el-option>
            <el-option label="否" :value="false"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐类型" class="order-type">
          <SelectDict ref="combo" v-model="searchData.goodsCombo" width="200px" dictCode="goods:combo"></SelectDict>
        </el-form-item>
        <el-form-item label=" " label-width="">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-divider></el-divider> -->
    <div class="header-tab">
      <div class="left-tab">
        <span @click="activeStatus = tab.value" v-for="tab in afterSaleStatus" :key="tab.value">
          <el-badge v-if="tab.value != 0" :class="[activeStatus == tab.value ? 'select-tab' : '']" :value="getStatic(tab.value)">
            {{ tab.name }}
          </el-badge>
          <el-badge v-else :class="[activeStatus == tab.value ? 'select-tab' : '']">
            {{ tab.name }}
          </el-badge>
        </span>
        <template> </template>
      </div>
      <!-- <el-button v-if="activeStatus != 2" type="primary" size="mini" @click="downloadExcel">批量分配</el-button>
      <el-button v-else type="primary" size="mini" @click="downloadExcel">批量放弃领单</el-button> -->
    </div>
    <!-- <div class="header-count">
      <div class="selected">
        <i class="el-icon-warning"></i>已选择：<span>{{ tableSelected.length }}</span>
      </div>
      <el-button type="" size="small">导出</el-button>
    </div> -->
    <tp-table
      @handleSelectionChange="handleSelectionChange"
      :isCheckBox="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import SelectDict from '@/views/components/select-dict.vue'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { searchTypeOrder, afterSaleStatus, dict_order_type } from '@/enum/dict.js'
import { dateFormat } from '@/utils/index.js'
import {
  handleAfterSaleAbandonAPI,
  getAfterSaleListAPI,
  getReviewOrderStaticAPI,
  getAfterSaleDrawAPI,
  downloadOrderContractAPI,
  downloadFileByUrl,
  downFile
} from './api'
const columns = [
  {
    label: '用户信息',
    prop: 'member',
    minWidth: '200',
    customRender(h, row) {
      return (
        <p>
          <div>姓名：{row['member']?.['memberName']}</div>
          <div>手机号：{row['member']?.['mobile']}</div>
        </p>
      )
    }
  },
  {
    label: '审核人',
    prop: 'reviewUser',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['reviewUser']?.['userName']}</p>
    }
  },
  {
    label: '客服',
    prop: 'kfUser',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['kfUser']?.['userName']}</p>
    }
  },

  {
    label: '订单信息',
    prop: 'createTime',
    minWidth: '300',
    customRender(h, row) {
      return (
        <div>
          <div>订单单号：{row['orderNo']}</div>
          <div>下单时间：{row['createTime']}</div>
          <div>订单状态：{row['statusName']}</div>
          <div>
            订单类型：
            <el-tag v-show={row['orderType'] == 1} type="primary" size="mini">
              普通订单
            </el-tag>
            <el-tag v-show={row['orderType'] == 2} type="warning" size="mini">
              门店订单
            </el-tag>
            <el-tag v-show={row['orderType'] == 3} type="success" size="mini">
              卡券订单
            </el-tag>
            <el-tag v-show={row['orderType'] == 4} color="#ffcccc54" style="color:#ff7744;border-color:#ffcccc54" size="mini">
              电车订单
            </el-tag>
            <el-tag v-show={row['enableAlipayFundAuth'] == true} type="danger" size="mini" style="margin-left: 5px">
              免押
            </el-tag>
          </div>
          <div>
            套餐类型：
            <el-tag type="primary" size="mini">
              {this.filterCombo(row['goodsCombo'])}
            </el-tag>
          </div>
        </div>
      )
    }
  },
  {
    label: '客户逾期天数',
    prop: 'member',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']?.['overdueDays']}</p>
    }
  },
  {
    label: '商品信息',
    prop: 'orderGoods',
    minWidth: '180',
    customRender(h, row) {
      return (
        <p>
          <div>{row['orderGoods']?.['skuTitle']}</div>

          <div>
            {' '}
            是否监管：
            <el-tag type={row['enableAbm'] ? 'primary' : 'warning'} size="mini">
              {row['enableAbm'] ? '是' : '否'}
            </el-tag>
          </div>
        </p>
      )
    }
  },
  {
    label: '价格',
    prop: 'rentPrice',
    minWidth: '220',
    align: 'center',
    customRender(h, row) {
      return (
        <div>
          <div class="price" v-show={row['goodsCombo'] == 'buyoutReturnAtTerm'}>
            <div class="item">
              <span>总租金(期数)：</span>
              <span>
                {row['rentPrice']} ({row['billPeriodNum']}期)
              </span>
            </div>
            <div class="item">
              <span>买断金：</span>
              <span> {row['buyoutPrice']}</span>
            </div>
            <div class="item">
              <span>溢价金：</span>
              <span>{row['overflowPrice']}</span>
            </div>
            <div class="item">
              <span>保险金：</span>
              <span> {row['insurancePrice']}</span>
            </div>
            <div class="item">
              <span>平台服务费：</span>
              <span> {row['servicePrice']}</span>
            </div>
          </div>
          <div class="price" v-show={row['goodsCombo'] == 'payAfterUse'}>
            <div class="item">
              <span>金额：</span>
              <span>{row['rentPrice']}</span>
            </div>
            <div class="item">
              <span>后付天数：</span>
              <span> {row['delayDayNum']}</span>
            </div>
          </div>
          <div class="price" v-show={row['goodsCombo'] == 'payAtOnce'}>
            <div class="item">
              <span>金额：</span>
              <span>{row['rentPrice']}</span>
            </div>
          </div>
        </div>
      )
    }
  },
  {
    label: '时间',
    prop: 'payTime',
    minWidth: '250',
    customRender(h, row) {
      return (
        <div>
          <div>支付时间：{row['payTime']}</div>
          <div>开始时间：{row['startRentDate']}</div>
          <div>结束时间：{row['endRentDate']}</div>
        </div>
      )
    }
  },
  {
    label: '发货信息',
    prop: 'orderAddress',
    minWidth: '250',
    customRender(h, row) {
      return (
        <div>
          <div>
            收货信息：{row['orderAddress']?.['contactName']} {row['orderAddress']?.['contactMobile']}
          </div>
          <div>
            收货地址：{row['orderAddress']?.['province']}
            {row['orderAddress']?.['city']}
            {row['orderAddress']?.['district']}
            {row['orderAddress']?.['detail']}
          </div>
        </div>
      )
    }
  },
  {
    label: '商户',
    prop: 'merchant',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['merchant']?.['merchantName']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '180',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <div class="caozuo">
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.after_sale_list_draw)}
              style="margin-right:10px;"
              v-show={this.activeStatus == 1}
              underline={false}
              type="primary"
              onClick={() => this.getAfterSaleDraw(row['orderNo'])}
            >
              领单
            </el-link>
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.after_sale_list_detail)}
              style="margin-right:10px;"
              v-show={this.activeStatus != 1}
              underline={false}
              type="primary"
              onClick={() =>
                this.$router.push({ name: 'AfterSaleDetail', params: { id: row['orderNo'] }, query: { memberId: row['member']?.['id'] } })
              }
            >
              详情
            </el-link>
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.after_sale_list_bill)}
              underline={false}
              type="primary"
              v-show={this.activeStatus == 2}
              onClick={() => this.$router.push({ name: 'AfterSaleBill', params: { id: row['orderNo'] } })}
            >
              账单
            </el-link>
          </div>
          <div class="caozuo">
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.after_sale_list_remark)}
              style="margin-right:10px;"
              v-show={this.activeStatus == 2}
              underline={false}
              type="primary"
              onClick={() => this.$router.push({ name: 'AfterSaleRemark', query: { memberId: row['member']?.['id'] } })}
            >
              跟进记录
            </el-link>
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.after_sale_list_hetong)}
              underline={false}
              v-show={this.activeStatus == 2}
              type="primary"
              onClick={() => this.$router.push({ name: 'AfterSaleContractDetail', params: { id: row['orderNo'] } })}
            >
              合同凭证
            </el-link>
          </div>
          <div class="caozuo">
            <el-link
              disabled={!this.isShowBtn(this.AUTH_BTN.after_sale_list_abandon)}
              underline={false}
              v-show={this.activeStatus == 2}
              type="primary"
              onClick={() => this.handleAbandon(row['orderNo'])}
            >
              放弃领单
            </el-link>
          </div>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        keywordName: '',
        keywordValue: '',
        timeSlot: [],
        leftCreateDate: '',
        rightCreateDate: '',
        leftMemberOverdueDays: '',
        rightMemberOverdueDays: '',
        enableAbm: '',
        orderType: '',
        goodsCombo: ''
      },
      searchTypeOrder,
      tableSelected: [],
      activeStatus: 0,
      afterSaleStatus,
      staticData: {},
      // 字典
      dict_order_type
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getReviewOrderStatic()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    },
    activeStatus: {
      immediate: true,
      handler(newV, oldV) {
        this.getList()
      }
    }
  },
  activated() {
    this.getList()
  },
  methods: {
    async getList() {
      const { timeSlot, keywordName, keywordValue, leftMemberOverdueDays, rightMemberOverdueDays, enableAbm, orderType, goodsCombo } = this.searchData
      const { activeStatus } = this
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        leftMemberOverdueDays,
        rightMemberOverdueDays,
        enableAbm,
        orderType,
        goodsCombo
      }
      // if (activeStatus != 0) params.afterSaleTab = activeStatus
      params.afterSaleTab = activeStatus - 0
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      if (keywordName) params[keywordName] = keywordValue
      const res = await getAfterSaleListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
      this.getReviewOrderStatic()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.activeStatus = '0'
      // this.getList()
    },
    handleSelectionChange(val) {
      this.tableSelected = val
    },
    async getReviewOrderStatic() {
      this.staticData = await getReviewOrderStaticAPI()
    },
    getStatic(value) {
      const { staticData } = this
      let num = 0
      switch (value) {
        case '1':
          num = staticData.waitTraceNum || 0
          break
        case '2':
          num = staticData.inTraceNum || 0
          break

        default:
          break
      }
      return num
    },
    // 领单
    getAfterSaleDraw(orderNo) {
      this.$confirm('此操作将领取该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getAfterSaleDrawAPI(orderNo).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '领单成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消领单'
          })
        })
    },
    // 放弃领单
    handleAbandon(orderNo) {
      this.$confirm('此操作将放弃该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          handleAfterSaleAbandonAPI(orderNo).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    downloadOrderContract(orderNo) {
      this.$confirm('此操作将下载该订单合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          downloadOrderContractAPI(orderNo).then(res => {
            // downloadFileByUrl(res.downloadUrl)
            const name = `${orderNo}_${dateFormat()}.pdf`
            downFile(res.downloadUrl, name)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 套餐类型
    filterCombo(str) {
      let name = ''
      const list = this.$refs.combo.list
      const res = list.find(item => item.value == str)
      if (res) {
        name = res.name
      }
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 25px;
        // .el-input {
        //   width: 250px;
        // }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .el-input-group {
          width: 320px;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }
  .header-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dcdfe6;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    .left-tab {
      & > span {
        cursor: pointer;
        margin-right: 30px;
      }
      .select-tab {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }

      .el-badge {
        .el-badge__content.is-fixed {
          top: 7px;
          right: 5px;
        }
        .el-badge__content {
          height: 20px;
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-count {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .selected {
      margin-right: 20px;
      padding: 0 10px;
      flex: 1;
      background-color: #e6f7ff;
      border: 1px solid #a3dcff;
      font-size: 12px;
      align-items: center;
      display: flex;
      i {
        color: #409eff;
        margin-right: 10px;
        font-size: 14px;
      }
      span {
        color: #409eff;
        font-size: 14px;
        margin-right: 10px;
      }
    }
    // .selected {
    //   // margin-top: 10px;
    //   padding: 0 10px;
    //   background-color: #e6f7ff;
    //   border: 1px solid #a3dcff;
    //   font-size: 12px;
    //   height: 30px;
    //   line-height: 30px;
    //   i {
    //     color: #409eff;
    //     margin-right: 10px;
    //     font-size: 14px;
    //   }
    //   span {
    //     color: #409eff;
    //     font-size: 14px;
    //     margin-right: 10px;
    //   }
    // }
  }
  .caozuo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    .item {
      min-width: 200px;
      display: flex;
      span {
        &:first-child {
          min-width: 100px;
          text-align: right;
        }
        &:last-child {
          min-width: 100px;
          text-align: left;
        }
      }
    }
  }
}
</style>
